import React  from "react"
import PropTypes from "prop-types"
import Layout from "../components/UI/Layout"

import { withStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Typography from "../components/Utils/Typography"
import Seo from "../components/Seo"

import useSiteMetadata from "../components/Data/useSiteMetadata"

const styles = theme => ({
  h1: {
    fontSize: 60,   
    [theme.breakpoints.down("sm")]: {
      fontSize: 36,      
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 24,      
    },    
  },
  h5: {
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 36,      
    },   
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,      
    },      
  },
  more: {
    marginTop: theme.spacing(2),
  },
})


function Impressum(props) {
  const { classes } = props
/*   const theme = useTheme() */
  const { siteDomain, publisher,jobtitle,organization} = useSiteMetadata()
  //const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <Layout>    
     <Seo  title={`Impressum" description="Impressum der  ${organization.name}  für die Website ${siteDomain} und assozierte Social Media Seiten.`}/>
      <Container>
        <Box mt={7} mb={12}>
          <Typography variant="h1" gutterBottom marked="center" align="center"  className={classes.h1}>
            Impressum
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            align="center"                     >
{/*           </Typography>
        </Box>
 */}
{/*       <Typography> */}
        <div>          
          der {organization.name} für die Domäne {siteDomain} und die zugehörigen Social Media Seiten bei Facebook, Twitter und Instagramm. 
        </div>
        <article id="Impressum" style={{textAlign: 'justify'}}>
       
        <p>Angaben gemäß § 5 TMG:</p>
        <p>
          {publisher}<br />
          {organization.street}<br />
          {organization.postcode} {organization.city} 
        </p>
        <h2><span style={{color: '#CDCDCD'}}>Kontakt:</span></h2>
        <p>          
          Telefon:{organization.phone} <br />
          E-Mail: {organization.email}
        </p>
        <p>Berufsbezeichnung: {jobtitle}.</p>
        <h2><span style={{color: '#CDCDCD'}}>Zugehörigkeit zu berufsständischen Institutionen:</span></h2>
        <p>
          Landesärztekammer Bremen<br />
          Schwachhauser Heerstraße 30<br />
          28209 Bremen<br />
          Telefon: +49  (0) 421 3404200<br />
          <a href="https://www.aekhb.de/">www.aekhb.de</a>
        </p>
        <h2><span style={{color: '#CDCDCD'}}>Berufsrechtliche Regelungen:</span></h2>
        <p>
          Es gilt die Berufsordnung der Landesärztekammer Bremen<br/>
           <a href="https://www.aekhb.de/data/mediapool/ae_re_rg_berufsordnung.pdf">https://www.aekhb.de/data/mediapool/ae_re_rg_berufsordnung.pdf</a>
           <br/>
          Heilberufegesetz des Landes Bremen 
          <br/>
          <a href="https://www.transparenz.bremen.de/sixcms/detail.php?<gsid=bremen2014_tp.c.67302.de&asl=bremen203_tpgesetz.c.55340.de&template=20_gp_ifg_meta_detail_d">
            https://www.transparenz.bremen.de/sixcms/detail.php?<br/>
            gsid=bremen2014_tp.c.67302.de&amp;<br/>asl=bremen203_tpgesetz.c.55340.de&amp;template=20_gp_ifg_meta_detail_d</a>.
          <br />
          Die Regelungen finden Sie auf der Homepage der Landesärztekammer Bremen (<a href="https://www.aekhb.de/">www.aekhb.de</a>)
          in der Rubrik Ärzte - Recht - Rechtsgrundlagen (<a href="https://www.aekhb.de/aerzte/recht/rechtsgrundlagen/3/26/index.html"> https://www.aekhb.de/aerzte/recht/rechtsgrundlagen/3/26/index.html</a>).
        </p>
        <h2><span style={{color: '#CDCDCD'}}>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</span></h2>
        <p>
        {publisher}<br />
        </p>
        <p>Die Impressumsangaben gelten auch für meine Profile in den sozialen Netzwerken Facebook, Instagram und Twitter.</p>
        <h2><span style={{color: '#CDCDCD'}} id="Bildrechte">Quellenangaben für die verwendeten Bilder und Grafiken:</span></h2>
        <p>
          Die verwendeteten Bilder sind eigene Aufnahmen oder offiziell von der <a href="https://www.btlmed.de/">Firma BTL Medizintechnik GmbH Im Starkfeld 57/2
          89231 Neu-Ulm </a> für Zwecke des Marketing zur Verfügung gestellt. Hier liegen die Rechte bei der BTL Medizintechnik GmbH.
          <br /> Das Herunterladen, einbetten oder jegliche sonstiges unauthorisierte Nutzung ist untersagt.
        </p>
        <em>Gemäß § 28 BDSG widerspreche ich jeder kommerziellen Verwendung und Weitergabe meiner Daten.</em>

        Informationspflicht gem. Verbraucherstreitbeilegungsgesetz (§ 36 VSBG) Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder bereit noch verpflichtet.
      </article>   
 

{/*       </Typography> */}
        </Typography>
        </Box>
      </Container>
    </Layout>
  )
}
Impressum.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Impressum)
